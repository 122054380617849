// src/pages/DashboardPage.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HeaderNav from '../components/Navs/HeaderNav';
import SideNav from '../components/Navs/SideNav';
import HomeContent from './Home/HomeContent';
import SenderContent from './Sender/SenderContent';
import SettingsContent from './Settings/SettingsContent';

const DashboardPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header Navigation */}
      <HeaderNav />

      <div className="flex pt-20">
        {/* Side Navigation */}
        <div className="w-64 flex-none">
          <SideNav />
        </div>

        {/* Main Content Area */}
        <div className="flex-1 p-10">
          <div id="home" className="mb-6"></div>
          <Routes>
            <Route path="home" element={<HomeContent />} />
            <Route path="sender" element={<SenderContent />} />
            <Route path="settings" element={<SettingsContent />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
