// src/pages/AuthPage.js
import React from 'react';
import Login from '../components/Auth/Login';

const AuthPage = () => {
  return (
    <div>
      <Login />
    </div>
  );
};

export default AuthPage;
