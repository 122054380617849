// src/pages/Home/HomeContent.js
import React from 'react';
import { FaPaperPlane, FaUsers, FaCog } from 'react-icons/fa';

const HomeContent = () => {
  return (
    <div id="home" className="max-w-7xl mx-auto mb-10 p-1">
      <h2 className="text-3xl font-bold mb-4">Welcome to Email Sender Dashboard</h2>
      <p className="text-lg">Your central hub for managing email campaigns efficiently.</p>
      
      {/* Quick Actions */}
      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-white p-8 rounded-lg shadow-md flex items-center">
          <FaPaperPlane className="text-5xl text-blue-500 mr-4" />
          <div>
            <h3 className="text-xl font-bold">New Email</h3>
            <p>Create and send a new email</p>
          </div>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-md flex items-center">
          <FaUsers className="text-5xl text-green-500 mr-4" />
          <div>
            <h3 className="text-xl font-bold">Manage Contacts</h3>
            <p>View and manage your contacts</p>
          </div>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-md flex items-center">
          <FaCog className="text-5xl text-yellow-500 mr-4" />
          <div>
            <h3 className="text-xl font-bold">Settings</h3>
            <p>Update your preferences</p>
          </div>
        </div>
      </div>
      
      {/* Recent Activity */}
      <div className="mt-10">
        <h3 className="text-xl font-bold mb-4">Recent Activity</h3>
        <ul className="bg-white p-6 rounded-lg shadow-md">
          <li className="flex items-center justify-between py-2">
            <span>Sent an email to John Doe</span>
            <span className="text-gray-500 text-sm">2 hours ago</span>
          </li>
          <li className="flex items-center justify-between py-2">
            <span>Updated contact list</span>
            <span className="text-gray-500 text-sm">5 hours ago</span>
          </li>
          <li className="flex items-center justify-between py-2">
            <span>Changed account settings</span>
            <span className="text-gray-500 text-sm">1 day ago</span>
          </li>
        </ul>
      </div>
      
      {/* Statistics */}
      <div className="mt-10">
        <h3 className="text-xl font-bold mb-4">Statistics</h3>
        <div className="bg-white p-6 rounded-lg shadow-md grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="flex items-center">
            <FaPaperPlane className="text-5xl text-blue-500 mr-4" />
            <div>
              <h4 className="text-lg font-bold">Emails Sent</h4>
              <p>1,234</p>
            </div>
          </div>
          <div className="flex items-center">
            <FaCog className="text-5xl text-purple-500 mr-4" />
            <div>
              <h4 className="text-lg font-bold">Open Rate</h4>
              <p>56%</p>
            </div>
          </div>
          <div className="flex items-center">
            <FaCog className="text-5xl text-red-500 mr-4" />
            <div>
              <h4 className="text-lg font-bold">Notifications</h4>
              <p>3</p>
            </div>
          </div>
        </div>
      </div>

      {/* Notifications */}
      <div className="mt-10">
        <h3 className="text-xl font-bold mb-4">Notifications</h3>
        <ul className="bg-white p-6 rounded-lg shadow-md">
          <li className="flex items-center justify-between py-2">
            <span>New feature available: A/B Testing</span>
            <span className="text-gray-500 text-sm">1 hour ago</span>
          </li>
          <li className="flex items-center justify-between py-2">
            <span>Scheduled maintenance on Friday</span>
            <span className="text-gray-500 text-sm">3 hours ago</span>
          </li>
          <li className="flex items-center justify-between py-2">
            <span>Account settings updated</span>
            <span className="text-gray-500 text-sm">1 day ago</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HomeContent;
