// src/components/Navs/HeaderNav.js
import React from 'react';
import { FaUserCircle } from 'react-icons/fa';

const HeaderNav = () => {
  return (
    <header className="w-full bg-white shadow-md p-4 flex justify-between items-center rounded-b-lg fixed top-0 z-50">
      <div className="flex items-center space-x-2 text-2xl font-bold text-gray-800">
        <span>Dashboard</span>
        <span className="text-gray-400">|</span>
      </div>
      <div className="flex items-center space-x-4">
        <FaUserCircle className="text-gray-600 text-2xl" />
        <span className="text-gray-600">User</span>
      </div>
    </header>
  );
};

export default HeaderNav;
