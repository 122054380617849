// src/App.js
import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthPage from './pages/AuthPage';
import DashboardPage from './pages/DashboardPage';
import { AuthProvider, useAuth } from './context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  const updateLastActive = () => {
    const now = Date.now().toString();
    localStorage.setItem('lastActive', now);
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateLastActive);
    window.addEventListener('keypress', updateLastActive);

    return () => {
      window.removeEventListener('mousemove', updateLastActive);
      window.removeEventListener('keypress', updateLastActive);
    };
  }, []);

  return (
    <AuthProvider>
      <Routes>
        <Route path="/login" element={<AuthPage />} />
        <Route path="/dashboard/*" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
      <ToastContainer />
    </AuthProvider>
  );
}

export default App;
