// src/pages/Settings/SMTPSettings.js
import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

const SMTPSettings = ({ smtpSettings, setSmtpSettings }) => (
  <div className="settings-section">
    <h3 className="text-xl font-bold mb-4 flex items-center"><FaEnvelope className="mr-2" /> SMTP Server Settings</h3>
    <label className="block mb-2">
      SMTP Server Address:
      <input
        type="text"
        value={smtpSettings.serverAddress}
        onChange={(e) => setSmtpSettings({ ...smtpSettings, serverAddress: e.target.value })}
        className="w-full p-2 border rounded mb-2"
      />
    </label>
    <label className="block mb-2">
      SMTP Port:
      <input
        type="number"
        value={smtpSettings.port}
        onChange={(e) => setSmtpSettings({ ...smtpSettings, port: e.target.value })}
        className="w-full p-2 border rounded mb-2"
      />
    </label>
    <label className="block mb-2">
      Username:
      <input
        type="text"
        value={smtpSettings.username}
        onChange={(e) => setSmtpSettings({ ...smtpSettings, username: e.target.value })}
        className="w-full p-2 border rounded mb-2"
      />
    </label>
    <label className="block mb-2">
      Password:
      <input
        type="password"
        value={smtpSettings.password}
        onChange={(e) => setSmtpSettings({ ...smtpSettings, password: e.target.value })}
        className="w-full p-2 border rounded mb-2"
      />
    </label>
  </div>
);

export default SMTPSettings;
