// src/components/Modals/TestSMTPModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailSendingLoader from '../Loader/EmailSendingLoader';
import { sendTestEmail } from '../../Api/Api';

const TestSMTPModal = ({ isOpen, onClose }) => {
  const [emails, setEmails] = useState(['']);
  const [sending, setSending] = useState(false);
  const [progress, setProgress] = useState(0);
  const [total] = useState(1); // Assume sending one email for test
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [smtpSettings, setSmtpSettings] = useState(null);

  useEffect(() => {
    const savedSettings = localStorage.getItem('smtpSettings');
    if (savedSettings) {
      setSmtpSettings(JSON.parse(savedSettings));
    }
  }, []);

  const handleSendTestEmail = async () => {
    if (!smtpSettings) {
      toast.error('SMTP settings are not configured.');
      return;
    }

    setSending(true);
    setProgress(0);
    setSuccess(false);
    setError(false);

    try {
      await sendTestEmail(emails, smtpSettings.fromEmail);
      setSuccess(true);
      toast.success('Test email sent successfully!');
    } catch (err) {
      setError(true);
      console.error("Error:", err);
      toast.error(`Failed to send test email: ${err.message}`);
    } finally {
      setSending(false);
    }
  };

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = emails.map((email, i) => (i === index ? value : email));
    setEmails(newEmails);
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="relative bg-white p-8 w-full max-w-2xl mx-auto mt-20 rounded-lg shadow-lg max-h-full overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Test SMTP</h2>
          <button onClick={onClose} className="text-gray-700 hover:text-gray-900">
            <FaTimes />
          </button>
        </div>
        {sending ? (
          <EmailSendingLoader progress={progress} total={total} success={success} error={error} />
        ) : (
          <div>
            <label className="block mb-2 font-bold">Test Email Addresses</label>
            {emails.map((email, index) => (
              <input
                key={index}
                type="email"
                value={email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                className="w-full p-2 border rounded mb-4"
                placeholder="Enter email to send test"
              />
            ))}
            <button
              onClick={handleAddEmail}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-blue-700 transition duration-300 mb-4"
            >
              Add Another Email
            </button>
            <div className="flex justify-end mt-4">
              <button
                onClick={handleSendTestEmail}
                className="bg-green-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-green-700 transition duration-300"
              >
                <FaCheck className="mr-2" /> Send Test Email
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestSMTPModal;
