// src/components/Modals/ManageContactsModal.js
import React, { useState, useEffect, useCallback } from 'react';
import { FaTimes, FaUpload, FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

const ManageContactsModal = ({ isOpen, onClose, setContacts }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [filePreview, setFilePreview] = useState([]);
  const [columnMapping, setColumnMapping] = useState({ firstName: '', lastName: '', email: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const processFileInChunks = useCallback((file) => {
    const chunkSize = 5 * 1024 * 1024; // 5MB chunks
    const totalChunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;

    const processNextChunk = () => {
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, file.size);

      if (window.Worker) {
        const fileWorker = new Worker(new URL('../../workers/fileWorker.js', import.meta.url));
        fileWorker.postMessage({ file, start, end });

        fileWorker.onmessage = (e) => {
          const { data } = e;

          if (data.error) {
            setError(data.error);
            setLoading(false);
            fileWorker.terminate();
            return;
          }

          const { previewData, headers } = data;
          setFilePreview((prev) => [...prev, ...previewData]);
          autoDetectColumns(headers);
          currentChunk++;

          if (currentChunk < totalChunks) {
            processNextChunk();
          } else {
            setLoading(false);
            fileWorker.terminate();
          }
        };
      } else {
        // Fallback if Web Workers are not supported
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);

            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName], { header: 1 });


            const headers = worksheet[0];
            const previewData = worksheet.slice(1).map((row) => {
              let rowData = {};
              headers.forEach((header, index) => {
                rowData[header] = row[index];
              });
              return rowData;
            });

            setFilePreview((prev) => [...prev, ...previewData]);
            autoDetectColumns(headers);
            currentChunk++;

            if (currentChunk < totalChunks) {
              processNextChunk();
            } else {
              setLoading(false);
            }
          } catch (err) {
            console.error('Error reading the file:', err); // Log error
            setError('Failed to read the file. Please ensure it is a valid CSV or XLSX file.');
            setLoading(false);
          }
        };
        reader.onerror = (err) => {
          console.error('Error reading the file:', err); // Log error
          setError('Failed to read the file.');
          setLoading(false);
        };

        const chunk = file.slice(start, end);
        reader.readAsArrayBuffer(chunk);
      }
    };

    setLoading(true);
    processNextChunk();
  }, []);

  useEffect(() => {
    if (uploadedFile) {
      processFileInChunks(uploadedFile);
    }
  }, [uploadedFile, processFileInChunks]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setUploadedFile(file);
  };

  const autoDetectColumns = (headers) => {
    const mapping = { firstName: '', lastName: '', email: '' };
    headers.forEach(header => {
      const normalizedHeader = header.toLowerCase();
      if (normalizedHeader.includes('first') && normalizedHeader.includes('name')) {
        mapping.firstName = header;
      } else if (normalizedHeader.includes('last') && normalizedHeader.includes('name')) {
        mapping.lastName = header;
      } else if (normalizedHeader.includes('email')) {
        mapping.email = header;
      }
    });
    setColumnMapping(mapping);
  };

  const handleColumnMappingChange = (field, value) => {
    setColumnMapping({ ...columnMapping, [field]: value });
  };

  const handleSaveContacts = () => {
    if (!columnMapping.firstName || !columnMapping.lastName || !columnMapping.email) {
      toast.error('Please map all columns.');
      return;
    }

    const contacts = filePreview.map(row => ({
      firstName: row[columnMapping.firstName] || '',
      lastName: row[columnMapping.lastName] || '',
      email: row[columnMapping.email] || '',
    }));

    setContacts(contacts);
    toast.success('Contacts saved successfully!');
    onClose();
  };

  const renderFilePreview = () => (
    <div className="mt-4 overflow-auto max-h-64">
      <h3 className="text-xl font-bold mb-4">Preview</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              {filePreview[0] && Object.keys(filePreview[0]).map((col, index) => (
                <th key={index} className="py-2 px-4 border-b">{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filePreview.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {Object.values(row).map((value, colIndex) => (
                  <td key={colIndex} className="py-2 px-4 border-b">{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderColumnMapping = () => (
    <div className="mt-4">
      <h3 className="text-xl font-bold mb-4">Map Columns</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div>
          <label className="block mb-2">First Name</label>
          <select
            value={columnMapping.firstName}
            onChange={(e) => handleColumnMappingChange('firstName', e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select Column</option>
            {filePreview[0] && Object.keys(filePreview[0]).map((col, index) => (
              <option key={index} value={col}>{col}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-2">Last Name</label>
          <select
            value={columnMapping.lastName}
            onChange={(e) => handleColumnMappingChange('lastName', e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select Column</option>
            {filePreview[0] && Object.keys(filePreview[0]).map((col, index) => (
              <option key={index} value={col}>{col}</option>
            ))}
          </select>
        </div>
        <div>
          <label className="block mb-2">Email</label>
          <select
            value={columnMapping.email}
            onChange={(e) => handleColumnMappingChange('email', e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select Column</option>
            {filePreview[0] && Object.keys(filePreview[0]).map((col, index) => (
              <option key={index} value={col}>{col}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="relative bg-white p-8 w-full max-w-6xl mx-auto mt-20 rounded-lg shadow-lg max-h-full overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Manage Contacts</h2>
          <button onClick={onClose} className="text-gray-700 hover:text-gray-900">
            <FaTimes />
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <label className="block mb-2 font-bold">Upload Contact List</label>
              <input type="file" onChange={handleFileUpload} className="w-full p-2 border rounded mb-4" />
              {loading && <div className="loader">Loading...</div>}
              {error && <div className="text-red-500">{error}</div>}
              <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-blue-700 transition duration-300">
                <FaUpload className="mr-2" /> Upload
              </button>
            </div>
            {filePreview.length > 0 && renderColumnMapping()}
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md overflow-auto max-h-96">
            {filePreview.length > 0 && renderFilePreview()}
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={handleSaveContacts}
            className="bg-green-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-green-700 transition duration-300"
          >
            <FaCheck className="mr-2" /> Save Contacts
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageContactsModal;
