import axios from 'axios';
import { toast } from 'react-toastify';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://sender.homeaidrelief.com/api', // replace with your actual API URL
  headers: {
    'Content-Type': 'application/json',
  },
});

export const sendTestEmail = async (emails, fromEmail) => {
  const smtpSettings = JSON.parse(localStorage.getItem('smtpSettings'));
  try {
    const response = await apiClient.post('/Sender/sendTest.php', {
      smtpSettings,
      emails,
      fromEmail
    });

    if (response.data.status === 'success') {
      return { message: 'Test email sent successfully!' };
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("Error sending test email.");
    throw new Error('Failed to send test email. ' + error.message);
  }
};

export const sendEmails = async (recipients, subject, body, onProgress) => {
  const smtpSettings = JSON.parse(localStorage.getItem('smtpSettings'));
  const finalDestination = "https://home.homeaidrelief.com";
  const encodedFinalDestination = btoa(finalDestination);
  const redirectUrl = `https://appw388.net/wp-content/url.php?url=${encodedFinalDestination}`;

  const link = redirectUrl; // #Link= ${redirectUrl} // placeholder

  if (!smtpSettings) {
    throw new Error('SMTP settings not found');
  }

  const totalEmails = recipients.length;
  for (let i = 0; i < totalEmails; i++) {
    const recipient = recipients[i];

    if (!recipient.email) {
      console.error('Recipient email is missing for one of the recipients.');
      continue;
    }

    try {
      // Generate voucher code for each recipient
      const voucherCode = await generateVoucherCode();
      
      const domain = extractDomain(recipient.email);
      const personalizedSubject = subject
        .replace('#firstName', recipient.firstName)
        .replace('#lastName', recipient.lastName)
        .replace('#Domain', domain);

      let personalizedBody = body
        .replace('#firstName', recipient.firstName)
        .replace('#lastName', recipient.lastName)
        .replace('#Voucher', voucherCode)
        .replace('#Domain', domain)
        .replace('#Link', link);

      await axios.post(`${process.env.REACT_APP_API_URL}/Sender/sendEmail.php`, {
        smtpSettings,
        recipient,
        subject: personalizedSubject,
        body: personalizedBody,
        link
      });

      if (onProgress) {
        onProgress(i + 1, totalEmails);
      }
    } catch (error) {
      console.error(`Failed to send email to a recipient: ${error.message}`);
      throw new Error(`Failed to send email to a recipient: ${error.message}`);
    }
  }
};

const generateVoucherCode = async () => {
  try {
    const response = await axios.post('https://homeaidrelief.com/api/Vouchers/createVoucher.php', {});
    return response.data.voucher_code;
  } catch (error) {
    console.error('Failed to generate voucher code.');
    return 'N/A';
  }
};

const extractDomain = (email) => {
  const domain = email.substring(email.indexOf('@') + 1, email.lastIndexOf('.'));
  return domain.charAt(0).toUpperCase() + domain.slice(1);
};

export const loginUser = async (key) => {
  try {
    const response = await apiClient.post('/login', { key });
    return response.data;
  } catch (error) {
    throw new Error('Invalid key, please try again.');
  }
};

export const fetchSettings = async () => {
  try {
    const response = await apiClient.get('/settings');
    return response.data;
  } catch (error) {
    toast.error('Failed to load settings');
  }
};

export const saveSettings = async (settings) => {
  try {
    const response = await apiClient.post('/settings', settings);
    toast.success('Settings saved successfully!');
    return response.data;
  } catch (error) {
    toast.error('Failed to save settings');
  }
};

export const fetchTemplates = async () => {
  try {
    const response = await apiClient.get('/Templates/getTemplates.php');
    return response.data;
  } catch (error) {
    console.error('Error fetching templates.');
    toast.error('Failed to load templates');
    return []; // Ensure templates is always an array
  }
};
