// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/Alerts/Alert';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    return storedIsAuthenticated === 'true';
  });
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();

  const MASTER_KEY = process.env.REACT_APP_MASTER_KEY;
  const INACTIVITY_TIMEOUT = 10 * 60 * 1000; // 10 minutes
  const ALERT_DISPLAY_TIME = 1000; // 10 seconds

  const login = useCallback(async (key) => {
    if (key === MASTER_KEY) {
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('lastActive', Date.now().toString());
      setIsAuthenticated(true);
      setAlert({ message: 'Login successful', type: 'success' });
      navigate('/dashboard/home');
    } else {
      console.error('Login failed: Invalid key');
      setAlert({ message: 'Invalid key, please try again.', type: 'error' });
    }
  }, [MASTER_KEY, navigate]);

  const logout = useCallback(() => {
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('lastActive');
    setIsAuthenticated(false);
    setAlert({ message: 'Logged out successfully', type: 'success' });
    navigate('/login');
  }, [navigate]);

  const handleInactivityLogout = useCallback(() => {
    const lastActive = parseInt(localStorage.getItem('lastActive'), 10);
    if (Date.now() - lastActive > INACTIVITY_TIMEOUT) {
      logout();
    }
  }, [INACTIVITY_TIMEOUT, logout]);

  useEffect(() => {
    const interval = setInterval(handleInactivityLogout, 60000); // Check every minute
    return () => clearInterval(interval);
  }, [handleInactivityLogout]);

  useEffect(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    if (storedIsAuthenticated === 'true') {
      setIsAuthenticated(true);
      localStorage.setItem('lastActive', Date.now().toString());
    }
  }, []);

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => setAlert(null), ALERT_DISPLAY_TIME);
      return () => clearTimeout(timer);
    }
  }, [alert]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, alert }}>
      {alert && <Alert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
