// src/pages/Sender/SenderContent.js
import React, { useState } from 'react';
import { FaPaperPlane, FaAddressBook, FaCogs, FaNetworkWired } from 'react-icons/fa';
import TestSMTPModal from '../../components/Modals/TestSMTPModal';
import PreferencesModal from '../../components/Modals/PreferencesModal';
import ManageContactsModal from '../../components/Modals/ManageContactsModal';
import NewEmailModal from '../../components/Modals/NewEmailModal';

const SenderContent = () => {
  const [isTestSMTPModalOpen, setIsTestSMTPModalOpen] = useState(false);
  const [isPreferencesModalOpen, setIsPreferencesModalOpen] = useState(false);
  const [isManageContactsModalOpen, setIsManageContactsModalOpen] = useState(false);
  const [isNewEmailModalOpen, setIsNewEmailModalOpen] = useState(false);
  const [preferences, setPreferences] = useState({});
  const [contacts, setContacts] = useState([]);

  return (
    <div id="sender" className="max-w-7xl -mt-8 mx-auto p-4 mb-10">
      <h2 className="text-3xl font-bold mb-4">Sender</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* New Email Section */}
        <div className="bg-white p-6 rounded-lg shadow-md transform transition duration-500 hover:scale-105">
          <div className="flex items-center mb-4">
            <FaPaperPlane className="text-4xl text-blue-500 mr-4" />
            <h3 className="text-xl font-bold">New Email</h3>
          </div>
          <p>Create and send a new email.</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsNewEmailModalOpen(true)}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-blue-700 transition duration-300"
            >
              <FaPaperPlane className="mr-2" /> Compose Email
            </button>
          </div>
        </div>

        {/* Manage Contacts Section */}
        <div className="bg-white p-6 rounded-lg shadow-md transform transition duration-500 hover:scale-105">
          <div className="flex items-center mb-4">
            <FaAddressBook className="text-4xl text-green-500 mr-4" />
            <h3 className="text-xl font-bold">Manage Contacts</h3>
          </div>
          <p>View and manage your contacts.</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsManageContactsModalOpen(true)}
              className="bg-green-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-green-700 transition duration-300"
            >
              <FaAddressBook className="mr-2" /> Manage Contacts
            </button>
          </div>
        </div>

        {/* Settings Section */}
        <div className="bg-white p-6 rounded-lg shadow-md transform transition duration-500 hover:scale-105">
          <div className="flex items-center mb-4">
            <FaCogs className="text-4xl text-yellow-500 mr-4" />
            <h3 className="text-xl font-bold">Settings</h3>
          </div>
          <p>Update your preferences.</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsPreferencesModalOpen(true)}
              className="bg-yellow-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-yellow-700 transition duration-300"
            >
              <FaCogs className="mr-2" /> Update Settings
            </button>
          </div>
        </div>

        {/* Test SMTP Section */}
        <div className="bg-white p-6 rounded-lg shadow-md transform transition duration-500 hover:scale-105">
          <div className="flex items-center mb-4">
            <FaNetworkWired className="text-4xl text-red-500 mr-4" />
            <h3 className="text-xl font-bold">Test SMTP</h3>
          </div>
          <p>Test your SMTP server configuration.</p>
          <div className="flex justify-end mt-4">
            <button
              onClick={() => setIsTestSMTPModalOpen(true)}
              className="bg-red-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-red-700 transition duration-300"
            >
              <FaNetworkWired className="mr-2" /> Test SMTP
            </button>
          </div>
        </div>
      </div>

      {/* Preferences Modal */}
      <PreferencesModal
        isOpen={isPreferencesModalOpen}
        onClose={() => setIsPreferencesModalOpen(false)}
        setPreferences={setPreferences}
      />

      {/* Manage Contacts Modal */}
      <ManageContactsModal
        isOpen={isManageContactsModalOpen}
        onClose={() => setIsManageContactsModalOpen(false)}
        setContacts={setContacts}
      />

      {/* Test SMTP Modal */}
      <TestSMTPModal
        isOpen={isTestSMTPModalOpen}
        onClose={() => setIsTestSMTPModalOpen(false)}
      />

      {/* New Email Modal */}
      <NewEmailModal
        isOpen={isNewEmailModalOpen}
        onClose={() => setIsNewEmailModalOpen(false)}
        preferences={preferences}
        contacts={contacts}
      />
    </div>
  );
};

export default SenderContent;
