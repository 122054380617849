// src/components/Alerts/Alert.js
import React from 'react';
import { FaExclamationTriangle, FaTimes } from 'react-icons/fa';

const Alert = ({ message, type, onClose }) => {
  const alertStyles = {
    success: 'bg-green-100 border-green-400 text-green-700',
    error: 'bg-red-100 border-red-400 text-red-700',
    warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
  };

  return (
    <div className={`border-l-4 p-4 ${alertStyles[type]} mb-4 rounded-lg flex items-center justify-between`}>
      <div className="flex items-center">
        <FaExclamationTriangle className="mr-2" />
        <span>{message}</span>
      </div>
      <button onClick={onClose} className="text-xl font-bold text-gray-700">
        <FaTimes />
      </button>
    </div>
  );
};

export default Alert;
