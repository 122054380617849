// src/components/Modals/EmailPreviewModal.js
import React, { useState } from 'react';
import { FaTimes, FaDesktop, FaMobileAlt } from 'react-icons/fa';
import './EmailPreviewModal.css';

const EmailPreviewModal = ({ isOpen, onClose, subject, body, isHtml, contacts }) => {
  const [viewMode, setViewMode] = useState('desktop');

  const renderPreviewContent = () => {
    const htmlContent = body ? body : '';

    return (
      <div className="preview-wrapper">
        {viewMode === 'desktop' ? (
          <div className="desktop-preview">
            <iframe 
              className="desktop-content"
              srcDoc={htmlContent}
              title="Desktop Preview"
            ></iframe>
          </div>
        ) : (
          <div className="mobile-preview">
            <iframe 
              className="mobile-content"
              srcDoc={htmlContent}
              title="Mobile Preview"
            ></iframe>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="relative bg-white p-8 w-full max-w-4xl mx-auto rounded-lg shadow-lg max-h-full overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <button onClick={onClose} className="text-gray-700 hover:text-gray-900">
            <FaTimes />
          </button>
        </div>
        <div className="mb-4">
          <h3 className="text-xl ">Subject: {subject}</h3>
          <ul className="list-disc list-inside">
            {contacts.slice(0, 2).map((contact, index) => (
              <li key={index}>
                {contact.firstName} {contact.lastName} ({contact.email})
              </li>
            ))}
            {contacts.length > 2 && (
              <li className="text-gray-600">...and {contacts.length - 2} more</li>
            )}
          </ul>
        </div>
        <div className="flex justify-between items-center mb-4">
          <div>
            <button 
              onClick={() => setViewMode('desktop')} 
              className={`mr-2 ${viewMode === 'desktop' ? 'active' : ''}`}
            >
              <FaDesktop />
            </button>
            <button 
              onClick={() => setViewMode('mobile')} 
              className={`${viewMode === 'mobile' ? 'active' : ''}`}
            >
              <FaMobileAlt />
            </button>
          </div>
        </div>
        <div className="preview-content">
          {renderPreviewContent()}
        </div>
      </div>
    </div>
  );
};

export default EmailPreviewModal;
