// src/components/Modals/NewEmailModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaPaperPlane, FaEye } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendEmails } from '../../Api/Api';
import EmailPreviewModal from './EmailPreviewModal';

const NewEmailModal = ({ isOpen, onClose, preferences, contacts }) => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isHtml, setIsHtml] = useState(true);
  const [sending, setSending] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  useEffect(() => {
    if (preferences) {
      setSubject(preferences.selectedSubject || '');
      setBody(preferences.selectedTemplate?.htmlContent || '');
      setIsHtml(preferences.emailType === 'html');
    }
  }, [preferences]);

  const handleSendEmail = async () => {
    if (!subject || !body || contacts.length === 0) {
      toast.error('Please fill in all fields and add at least one contact.');
      return;
    }

    setSending(true);

    try {
      await sendEmails(contacts, subject, body, (current, total) => {
        toast.info(`Sending email ${current} of ${total}`);
      });
      toast.success('Emails sent successfully!');
      onClose();
    } catch (err) {
      toast.error(`Failed to send emails: ${err.message}`);
    } finally {
      setSending(false);
    }
  };

  const handlePreviewEmail = () => {
    setIsPreviewModalOpen(true);
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="relative bg-white p-8 w-full max-w-6xl mx-auto mt-20 rounded-lg shadow-lg max-h-full overflow-hidden">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Compose New Email</h2>
          <button onClick={onClose} className="text-gray-700 hover:text-gray-900">
            <FaTimes />
          </button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-4">
          <div className="overflow-y-auto max-h-[60vh] pr-4">
            <label className="block mb-2 font-bold">Subject</label>
            <div className="flex justify-between items-center p-2 border rounded mb-4">
              <span>{subject}</span>
            </div>
            <label className="block mb-2 font-bold">Message</label>
            <div className="p-2 border rounded mb-4 max-h-[40vh] overflow-y-auto">
              <div dangerouslySetInnerHTML={{ __html: body }}></div>
            </div>
          </div>
          <div className="overflow-y-auto max-h-[60vh] pr-4">
            <label className="block mb-2 font-bold">Contacts Preview</label>
            <div className="bg-gray-100 p-4 rounded-lg max-h-[60vh] overflow-y-auto">
              {contacts.length > 0 ? (
                contacts.slice(0, 5).map((contact, index) => (
                  <div key={index} className="p-2 border-b">
                    {contact.firstName || ''} {contact.lastName || ''} ({contact.email || ''})
                  </div>
                ))
              ) : (
                <div className="p-2 text-gray-600">No contacts available</div>
              )}
              {contacts.length > 5 && (
                <div className="p-2 text-gray-600">
                  ...and {contacts.length - 5} more
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handlePreviewEmail}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-blue-700 transition duration-300 mr-2"
          >
            <FaEye className="mr-2" /> Preview
          </button>
          <button
            onClick={handleSendEmail}
            className={`bg-green-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-green-700 transition duration-300 ${sending ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={sending}
          >
            <FaPaperPlane className="mr-2" /> Send
          </button>
        </div>
        <EmailPreviewModal
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          subject={subject}
          body={body}
          isHtml={isHtml}
          contacts={contacts}
        />
      </div>
    </div>
  );
};

export default NewEmailModal;
