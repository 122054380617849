// src/components/Navs/SideNav.js
import React from 'react';
import { FaHome, FaPaperPlane, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { Link } from 'react-router-dom';

const SideNav = () => {
  const { logout } = useAuth();

  return (
    <nav className="space-y-4 p-6 fixed">
      <Link to="/dashboard/home" className="flex items-center text-lg text-gray-600 hover:text-gray-800">
        <FaHome className="mr-2" />
        Home
      </Link>
      <Link to="/dashboard/sender" className="flex items-center text-lg text-gray-600 hover:text-gray-800">
        <FaPaperPlane className="mr-2" />
        Sender
      </Link>
      <Link to="/dashboard/settings" className="flex items-center text-lg text-gray-600 hover:text-gray-800">
        <FaCog className="mr-2" />
        Settings
      </Link>
      <button onClick={logout} className="flex items-center text-lg text-gray-600 hover:text-gray-800">
        <FaSignOutAlt className="mr-2" />
        Logout
      </button>
    </nav>
  );
};

export default SideNav;
