// src/pages/Settings/EncryptionSettings.js
import React from 'react';
import { FaLock } from 'react-icons/fa';

const EncryptionSettings = ({ smtpSettings, setSmtpSettings }) => {
  const handleToggle = (encryptionType) => {
    setSmtpSettings({ ...smtpSettings, encryption: encryptionType });
  };

  return (
    <div className="settings-section">
      <h3 className="text-xl font-bold mb-4 flex items-center"><FaLock className="mr-2" /> Encryption Type</h3>
      <div className="flex flex-col space-y-4 mb-4">
        <div className="flex items-center justify-between">
          <span>None</span>
          <input
            type="checkbox"
            checked={smtpSettings.encryption === 'None'}
            onChange={() => handleToggle('None')}
            className="toggle-switch"
          />
        </div>
        <div className="flex items-center justify-between">
          <span>SSL</span>
          <input
            type="checkbox"
            checked={smtpSettings.encryption === 'SSL'}
            onChange={() => handleToggle('SSL')}
            className="toggle-switch"
          />
        </div>
        <div className="flex items-center justify-between">
          <span>TLS</span>
          <input
            type="checkbox"
            checked={smtpSettings.encryption === 'TLS'}
            onChange={() => handleToggle('TLS')}
            className="toggle-switch"
          />
        </div>
      </div>
      <label className="block mb-2">
        From Email:
        <input
          type="email"
          value={smtpSettings.fromEmail}
          onChange={(e) => setSmtpSettings({ ...smtpSettings, fromEmail: e.target.value })}
          className="w-full p-2 border rounded mb-2"
        />
      </label>
      <label className="block mb-2">
        From Name:
        <input
          type="text"
          value={smtpSettings.fromName}
          onChange={(e) => setSmtpSettings({ ...smtpSettings, fromName: e.target.value })}
          className="w-full p-2 border rounded mb-2"
        />
      </label>
    </div>
  );
};

export default EncryptionSettings;
