import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaTimes, FaEye, FaSave } from 'react-icons/fa';
import PreviewModal from './PreviewModal';
import { fetchTemplates } from '../../Api/Api';

const PreferencesModal = ({ isOpen, onClose, setPreferences }) => {
  const [templates, setTemplates] = useState([]);
  const [activeTab, setActiveTab] = useState('templates');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [emailType, setEmailType] = useState('html');
  const [signature, setSignature] = useState('');
  const [batchFrequency, setBatchFrequency] = useState('immediate');
  const [batchSize, setBatchSize] = useState('500');
  const [delay, setDelay] = useState('0');
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  useEffect(() => {
    const loadTemplates = async () => {
      try {
        const templates = await fetchTemplates();
        if (templates.length > 0) {
          setTemplates(templates);
          setSelectedTemplate(templates[0]);
          setSelectedSubject(templates[0].subject);
          toast.success('Templates loaded successfully');
        }
      } catch (error) {
        toast.error('Failed to load templates');
      }
    };

    if (isOpen) {
      loadTemplates();
    }
  }, [isOpen]);

  const handleSavePreferences = () => {
    const preferences = {
      selectedTemplate,
      selectedSubject,
      emailType,
      signature,
      batchFrequency,
      batchSize,
      delay,
    };
    setPreferences(preferences);
    toast.success('Preferences saved successfully');
  };

  const handlePreviewClick = () => {
    setIsPreviewModalOpen(true);
  };

  const renderTemplates = () => (
    <div>
      <h3 className="text-xl font-bold mb-4">Email Templates</h3>
      <label className="block mb-2">Select Template</label>
      <select
        value={selectedTemplate ? selectedTemplate.id : ''}
        onChange={(e) => {
          const template = templates.find((t) => t.id === e.target.value);
          setSelectedTemplate(template);
          setSelectedSubject(template.subject);
        }}
        className="w-full p-2 border rounded mb-4"
      >
        {templates.map((template) => (
          <option key={template.id} value={template.id}>
            {template.name}
          </option>
        ))}
      </select>
      <label className="block mb-2">Subject</label>
      <input
        type="text"
        value={selectedSubject}
        onChange={(e) => setSelectedSubject(e.target.value)}
        className="w-full p-2 border rounded mb-4"
        placeholder="Enter email subject"
      />
      <label className="block mb-2">Email Type</label>
      <select
        value={emailType}
        onChange={(e) => setEmailType(e.target.value)}
        className="w-full p-2 border rounded mb-4"
      >
        <option value="html">HTML</option>
        <option value="plain">Plain Text</option>
      </select>
      <button
        onClick={handlePreviewClick}
        className="bg-gray-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-gray-700 transition duration-300 mb-4"
      >
        <FaEye className="mr-2" /> Preview
      </button>
    </div>
  );

  const renderSignature = () => (
    <div>
      <h3 className="text-xl font-bold mb-4">Email Signature</h3>
      <textarea
        value={signature}
        onChange={(e) => setSignature(e.target.value)}
        className="w-full p-2 border rounded mb-4"
        rows="5"
        placeholder="Enter your email signature"
      />
    </div>
  );

  const renderBatchFrequency = () => (
    <div>
      <h3 className="text-xl font-bold mb-4">Batch Frequency</h3>
      <label className="block mb-2">Batch Size</label>
      <select
        value={batchSize}
        onChange={(e) => setBatchSize(e.target.value)}
        className="w-full p-2 border rounded mb-4"
      >
        <option value="500">500</option>
        <option value="1000">1000</option>
        <option value="2000">2000</option>
      </select>
      <label className="block mb-2">Delay (in seconds)</label>
      <input
        type="number"
        value={delay}
        onChange={(e) => setDelay(e.target.value)}
        className="w-full p-2 border rounded mb-4"
        placeholder="Enter delay in seconds"
      />
      <label className="block mb-2">Batch Frequency</label>
      <select
        value={batchFrequency}
        onChange={(e) => setBatchFrequency(e.target.value)}
        className="w-full p-2 border rounded mb-4"
      >
        <option value="immediate">Immediate</option>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
      </select>
    </div>
  );

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
      <div className="relative bg-white p-8 w-full max-w-4xl mx-auto mt-20 rounded-lg shadow-lg max-h-full overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Settings Preferences</h2>
          <button onClick={onClose} className="text-gray-700 hover:text-gray-900">
            <FaTimes />
          </button>
        </div>
        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab('templates')}
            className={`py-2 px-4 rounded ${activeTab === 'templates' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Templates
          </button>
          <button
            onClick={() => setActiveTab('signature')}
            className={`py-2 px-4 rounded ${activeTab === 'signature' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Signature
          </button>
          <button
            onClick={() => setActiveTab('batchFrequency')}
            className={`py-2 px-4 rounded ${activeTab === 'batchFrequency' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Batch Frequency
          </button>
        </div>
        <div>
          {activeTab === 'templates' && renderTemplates()}
          {activeTab === 'signature' && renderSignature()}
          {activeTab === 'batchFrequency' && renderBatchFrequency()}
        </div>
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleSavePreferences}
            className="bg-green-500 text-white font-bold py-2 px-4 rounded flex items-center hover:bg-green-700 transition duration-300"
          >
            <FaSave className="mr-2" /> Save Preferences
          </button>
        </div>

        {/* Preview Modal */}
        <PreviewModal
          isOpen={isPreviewModalOpen}
          onClose={() => setIsPreviewModalOpen(false)}
          template={selectedTemplate}
          subject={selectedSubject}
          emailType={emailType}
        />
      </div>
    </div>
  );
};

export default PreferencesModal;
