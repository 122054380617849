// src/components/Loader/EmailSendingLoader.js
import React from 'react';
import { FaEnvelope, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './EmailSendingLoader.css'; // Create a corresponding CSS file for styles

const EmailSendingLoader = ({ progress, total, success, error }) => {
  return (
    <div className="loader-container">
      <div className="loader">
        <FaEnvelope className="loader-icon" />
        <div className="loader-text">
          {success && (
            <div className="success-message">
              <FaCheckCircle className="success-icon" /> Email sent successfully!
            </div>
          )}
          {error && (
            <div className="error-message">
              <FaTimesCircle className="error-icon" /> Failed to send email.
            </div>
          )}
          {!success && !error && (
            <div>
              <p>Sending Emails...</p>
              <p>{`${progress}/${total}`}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailSendingLoader;
