import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import SMTPSettings from './SMTPSettings';
import EncryptionSettings from './EncryptionSettings';

const SettingsContent = () => {
  const [smtpSettings, setSmtpSettings] = useState({
    serverAddress: '',
    port: '',
    encryption: 'None',
    username: '',
    password: '',
    fromEmail: '',
    fromName: ''
  });

  const toastShown = useRef(false);

  useEffect(() => {
    if (!toastShown.current) {
      const savedSettings = localStorage.getItem('smtpSettings');
      if (savedSettings) {
        setSmtpSettings(JSON.parse(savedSettings));
        toast.success('SMTP settings loaded.');
        toastShown.current = true;
      }
    }
  }, []); // Ensure this effect runs only once when the component mounts

  const handleSave = () => {
    localStorage.setItem('smtpSettings', JSON.stringify(smtpSettings));
    toast.success('SMTP settings saved.');
  };

  const handleLoad = () => {
    const savedSettings = localStorage.getItem('smtpSettings');
    if (savedSettings) {
      setSmtpSettings(JSON.parse(savedSettings));
      toast.success('SMTP settings loaded.');
    } else {
      toast.error('No saved settings found.');
    }
  };

  return (
    <div id="settings" className="max-w-5xl mx-auto p-4 mb-10">
      <h2 className="text-3xl font-bold mb-4">Settings</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* SMTP Settings Section */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <SMTPSettings smtpSettings={smtpSettings} setSmtpSettings={setSmtpSettings} />
          <div className="flex justify-between mt-4">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700"
            >
              Save
            </button>
            <button
              onClick={handleLoad}
              className="bg-gray-500 text-white font-bold py-2 px-4 rounded hover:bg-gray-700"
            >
              Load
            </button>
          </div>
        </div>

        {/* Encryption Type Section */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <EncryptionSettings smtpSettings={smtpSettings} setSmtpSettings={setSmtpSettings} />
        </div>

        {/* Current SMTP Section */}
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-xl font-bold mb-4">Current SMTP</h3>
          <div className="p-4 border rounded">
            {smtpSettings.serverAddress || 'No SMTP server configured'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsContent;
